import React, { useState } from "react";
import { data } from "../data/data.js";
import MenuModal from "./ModalMenu.jsx";
import restauranteMeraki from "../assets/locales/meraki.png";
import restauranteMalditaDicha from "../assets/locales/malditadicha.png";
import restauranteParrilla from "../assets/locales/parrila.png";
import restauranteCelestial from "../assets/locales/celestila.png";
import restauranteLlegadero from "../assets/locales/llegadero2.png";
import restaurantePoloSur from "../assets/locales/polo2.png";

const Food = () => {
  const [foods, setFoods] = useState(data.meraki);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [, setSelectedFood] = useState(null);

  const restaurantImages = {
    meraki: restauranteMeraki,
    malditaDicha: restauranteMalditaDicha,
    parrilla: restauranteParrilla,
    celestial: restauranteCelestial,
    llegadero: restauranteLlegadero,
    polosur: restaurantePoloSur,
  };

  const filterType = (category) => {
    if (category === "Todos") {
      setFoods(data.meraki);
    } else {
      setFoods(data[category]);
    }
  };

  const handleImageClick = (food) => {
    setSelectedFood(food.id);
    setIsModalOpen(true);
    setShowConfirmation(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="max-w-[1640px] m-auto px-4 py-12 m">
      <h1 className="text-orange-600 font-bold text-4xl text-center m-2">
        Menú <span className="font-bold text-white">LA QUINTA</span> categorias
      </h1>

      {/* Filter Row */}
      <div className="flex flex-col lg:flex-row justify-between text-center">
        {/* Fliter Type */}
        <div className="text-center">
          <p className="font-bold text-white mt-8 text-2xl text-center">
            Selecciona El Menú Del Restaurante
          </p>
          <div className="flex justfiy-between flex-wrap mt-8">
            <button
              onClick={() => filterType("meraki")}
              className="m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white w-25 h-10"
              id="meraki"
            >
              Meraki
            </button>
            <button
              onClick={() => filterType("malditaDicha")}
              className="m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white w-25 h-10"
              id="malditaDicha"
            >
              Maldita Dicha
            </button>
            <button
              onClick={() => filterType("parrilla")}
              className="m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white w-25 h-10"
              id="parrilla"
            >
              Parrilla
            </button>
            <button
              onClick={() => filterType("celestial")}
              className="m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white w-25 h-10"
              id="celestial"
            >
              Celestial
            </button>
            <button
              onClick={() => filterType("llegadero")}
              className="m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white w-25 h-10"
              id="llegadero"
            >
              LLegadero
            </button>
            <button
              onClick={() => filterType("polosur")}
              className="m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white w-25 h-10"
              id="polosur"
            >
              Polo Sur
            </button>
          </div>
        </div>
      </div>

      {/* Display foods */}
      <div className="text-center mt-10 font-bold text-xl text-white lg:hidden">
            <h1>puedes hacer un pedido seleccionando alguno de los productos del menú</h1>
      </div>
      <div className="mt-6">
      {foods.length > 0 && (
        <img
          src={restaurantImages[foods[0].category]}
          alt={`Restaurante ${foods[0].category}`}
          className="w-full h-auto object-cover rounded-lg mb-4 max-h-[400px] rounded lg:hidden"
        />
      )}
    </div>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 pt-4 mt-10">
        {foods.map((item, index) => (
          <div
            key={index}
            className="border shadow-lg rounded-lg hover:scale-105 duration-300"
            onClick={() => handleImageClick(item)}
          >
            <img
              src={item.image}
              alt={item.name}
              className="w-full h-[200px] object-cover rounded-t-lg"
            />
            <div className="flex justify-between px-2 py-4">
              <p className="text-white font-bold text-sm">{item.name}</p>
              <p>
                <span className="bg-orange-500 text-white p-1 rounded-full text-sm">
                  ${item.price}
                </span>
              </p>
            </div>
          </div>
        ))}
      </div>
      <MenuModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        menuItems={foods}
      />
      {showConfirmation && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-parkk bg-cover p-16 rounded-3xl">
            <p className="text-xl bg-black font-bold text-white rounded">
              ¿Deseas hacer un pedido?
            </p>
            <div className="flex justify-center mt-4">
              <button
                onClick={() => {
                  setIsModalOpen(true);
                  setShowConfirmation(false);
                }}
                className="bg-green-500 text-white px-4 py-2 rounded-md mr-4"
              >
                Sí
              </button>
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  setShowConfirmation(false);
                }}
                className="bg-red-500 text-white px-4 py-2 rounded-md"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Food;
