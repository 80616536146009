import React, { useEffect, useState } from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoMdAddCircle } from "react-icons/io";
import { BsFillCartFill } from "react-icons/bs";
import { IoIosRemoveCircle } from "react-icons/io";

const MenuModal = ({ isOpen, onClose, menuItems }) => {
  const [selectedProducts, setSelectedProducts] = useState(new Map());
  const [tableNumber, setTableNumber] = useState("");
  const [tableName, setTableName] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    const body = document.body;
    const navbar = document.getElementById("navbar");

    if (isOpen) {
      body.classList.add("overflow-hidden");
      navbar.classList.add("hidden");
    } else {
      body.classList.remove("overflow-hidden");
      navbar.classList.remove("hidden");
    }

    return () => {
      body.classList.remove("overflow-hidden");
      navbar.classList.remove("hidden");
    };
  }, [isOpen]);

  const handleAddToCart = (item) => {
    setSelectedProducts((prevSelected) => {
      const updatedProducts = new Map(prevSelected);
      if (updatedProducts.has(item.id)) {
        updatedProducts.set(item.id, {
          ...item,
          quantity: updatedProducts.get(item.id).quantity + 1,
        });
      } else {
        updatedProducts.set(item.id, { ...item, quantity: 1 });
      }
      return updatedProducts;
    });
  };

  const handleRemoveFromCart = (itemId) => {
    setSelectedProducts((prevSelected) => {
      const updatedProducts = new Map(prevSelected);
      if (updatedProducts.has(itemId)) {
        const updatedProduct = {
          ...updatedProducts.get(itemId),
          quantity: updatedProducts.get(itemId).quantity - 1,
        };
        if (updatedProduct.quantity <= 0) {
          updatedProducts.delete(itemId);
        } else {
          updatedProducts.set(itemId, updatedProduct);
        }
      }
      return updatedProducts;
    });
  };

  const resetOrder = () => {
    setSelectedProducts(new Map());
    setTableNumber("");
    setTableName("");
    setSelectedCategories([]);
  };

  const handleWhatsAppClick = () => {
    //GENERAL
    let phoneNumber = "573243069765";
    //LLEGADERO
    const isLlegaderoTouch = selectedCategories.includes("llegadero");

    if (isLlegaderoTouch) {
      phoneNumber = "573122915481";
    }
    //POLO SUR
    const isPoloSurTouch = selectedCategories.includes("polosur");

    if (isPoloSurTouch) {
      phoneNumber = "573243913973";
    }

    //PARRILLA
    const isParrillaTouch = selectedCategories.includes("parrilla");
    if (isParrillaTouch) {
      phoneNumber = "573001203984";
    }

    let message = "";

    selectedCategories.forEach((category) => {
      let totalQuantity = 0;
      let totalPriceCents = 0;

      switch (category) {
        case "meraki":
          message += `👋¡Hola Bienvenidos a LA QUINTA!❣️\n\n🍟 🍕¡Pedido de Meraki!🍕 🍟\n\n`;
          break;
        case "parrilla":
          message += `👋¡Hola Bienvenidos a LA QUINTA!❣️\n\n🥩 ¡Pedido de Parrilla! 🥩\n\n`;
          break;
        case "malditaDicha":
          message += `👋¡Hola Bienvenidos a LA QUINTA!❣️\n\n🍔¡Pedido de Maldita Dicha!🍔 \n\n`;
          break;
        case "llegadero":
          message += `👋¡Hola Bienvenidos a LA QUINTA!❣️\n\n🍻 ¡Pedido de Llegadero! 🍻\n\n`;
          break;
        case "celestial":
          message += `👋¡Hola Bienvenidos a LA QUINTA!❣️\n\n☕ 🍰¡Pedido de Celestial!🍰 ☕\n\n`;
          break;
        case "polosur":
          message += `👋¡Hola Bienvenidos a LA QUINTA!❣️\n\n🍦 ¡Pedido de Polo Sur! 🍦\n\n`;
          break;
        default:
          message += `🍽️ ¡Pedido! 🍽️\n\n`;
      }

      selectedProducts.forEach((product) => {
        if (product.category === category) {
          totalQuantity += product.quantity;
          if (!isNaN(product.price)) {
            totalPriceCents += product.price * product.quantity * 100;
          }
          message += `📝 ${product.name} = *${product.quantity}*\n`;
        }
      });

      const totalPrice = isNaN(totalPriceCents / 100)
        ? 0
        : totalPriceCents / 100;
      message += `\n🛒Total de Productos: ${totalQuantity}\n\n`;
      message += `💳Precio Total: *${totalPrice
        .toFixed(3)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}* *COP*\n\n`;
      message += `Mesa 🪑 ó Domicilio 📍: *${tableNumber}*\n\n`;
      message += `Nombre 👨‍👨‍👧‍👦: *${tableName}*\n\n`;

      message += "------------------------\n\n";
    });

    message += `¡Gracias, Pronto Estará Tu Pedido! ✔️`;

    const encodedText = encodeURIComponent(message);

    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedText}`;
    window.open(whatsappLink, "_blank");

    onClose();
    setSelectedProducts(new Map());
    setTableNumber("");
    setTableName("");
    setSelectedCategories([]);
  };

  const handleAddProduct = () => {
    setSelectedProducts(new Map());
    setTableNumber("");
    setTableName("");
    setSelectedCategories([]);
    onClose();
  };

  const handleCategorySelection = (category) => {
    if (!selectedCategories.includes(category)) {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  return (
    <div
      className={`modal ${
        isOpen
          ? "opacity-100 pointer-events-auto"
          : "opacity-0 pointer-events-none"
      }`}
    >
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-90 cursor-pointer"></div>

        <div
          className="bg-modal bg-cover modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-lg shadow-lg z-50 overflow-hidden"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "540px",
            overflowY: "auto",
            maxHeight: "80vh",
          }}
        >
          <div className="modal-content p-4 justify-center">
            <h2 className="text-xl font-bold mb-2 mt-2 bg-black text-center text-red-400 h2 capitalize">
              M E N Ú
            </h2>

            <div className="space-y-4 max-h-72 overflow-y-auto">
              {menuItems &&
                menuItems
                  .filter((item) => item !== null)
                  .map((item, index) => (
                    <div
                      key={index}
                      className="border-black bg-gray-800 text-white border rounded p-4 mb-4 opacity-90"
                      onClick={() => handleCategorySelection(item.category)}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <img
                            src={item.image}
                            alt={item.name}
                            className="w-20 h-auto mr-4"
                          />
                          <div>
                            <p className="text-lg font-semibold text-white">
                              {item.name}
                            </p>
                            <p className="text-sm text-black">
                              {item.description}
                            </p>
                            {item.price ? (
                              <p className="text-base font-bold text-accent text-green-500">
                                {item.price}
                              </p>
                            ) : (
                              <p className="font-bold text-accent text-blue-500">
                                Pregunta por el precio
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col items-end">
                          <button
                            onClick={() => handleAddToCart(item)}
                            className="text-accent text-lg text-green-500 mb-1"
                          >
                            <IoMdAddCircle />
                          </button>
                          <button
                            onClick={() => handleRemoveFromCart(item.id)}
                            className="text-accent text-lg text-red-500"
                          >
                            <IoIosRemoveCircle />
                          </button>
                        </div>
                      </div>
                      <span className="text-accent mt-2 bg-black text-white font-bold rounded">
                        Cantidad -{" "}
                        <span className="text-orange-500">{item.name}</span> :{" "}
                        {selectedProducts.get(item.id)?.quantity || 0}
                      </span>
                    </div>
                  ))}
            </div>

            <div className="mt-4">
              <label className="text-white font-bold bg-black m-2 rounded">
                numero de mesa ó tu ubicación:
              </label>
              <input
                placeholder="Ingresa (# Mesa) ó (Domicilio)"
                type="text"
                value={tableNumber}
                onChange={(e) => setTableNumber(e.target.value)}
                className="bg-gray-200 px-2 py-1 rounded"
              />
              <div className="mt-3">
                <label className="text-white font-bold bg-black m-2 rounded">
                  cliente:
                </label>
              </div>
              <input
                placeholder="Tu nombre"
                type="text"
                value={tableName}
                onChange={(e) => setTableName(e.target.value)}
                className="bg-gray-200 px-2 py-1 rounded"
              />
            </div>

            <div className="flex justify-between mt-4">
              <button
                className="bg-accent text-white py-2 px-4 rounded-full hover:bg-accent-dark bg-red-500"
                onClick={() => {
                  resetOrder();
                  onClose();
                }}
              >
                Cancelar
              </button>
              <button
                className="flex items-center bg-green-400 text-white py-1 px-3 rounded-full hover:bg-accent-dark"
                onClick={handleWhatsAppClick}
              >
                <IoLogoWhatsapp className="mr-2" /> Pedido
              </button>
              <button className="bg-blue-400 text-white py-2 px-4 flex items-center rounded-full hover:bg-accent-dark">
                <div>
                  <span>
                    <BsFillCartFill />
                    {Array.from(selectedProducts.values()).length}
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuModal;
