import React from "react";
import { motion } from "framer-motion";
import Newsletter from "../data/Newsletter";
import Socials from "../data/Socials";
import { footerData } from "../data/data";
import { fadeIn, staggerContainer } from "../variants";

const Footer = () => {
  const { contact, hours, social } = footerData;

  return (
    <footer
      className="relative z-20 lg:hidden bg-dark bg-footer rounded-xl bg-center bg-no-repeat bg-cover pt-20 text-white lg:bg-hidden"
      id="contact"
    >
      <div className="container mx-auto mb-10 lg:text-center">
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView={"show"}
          className="flex flex-col lg:flex-row gap-y-12 rounded-xl lg:items-center"
        >
          <motion.div variants={fadeIn("up", "tween", 0.4, 1.6)} className="lg:w-1/2">
            <Newsletter />
          </motion.div>
          <motion.div variants={fadeIn("up", "tween", 0.8, 1.6)} className="lg:w-1/2">
            <div className="flex flex-col lg:flex-row lg:gap-x-8 gap-y-12 lg:gap-y-0 lg:mb-12 text-center font-bold lg:items-start">
              <div className="flex-1 lg:max-w-xs">
                <div className="capitalize text-lg lg:text-xl font-bold text-xl text-white mb-4 rounded-2xl ">
                  {contact.title}
                </div>
                <div className="capitalize">{contact.address}</div>
                <div className="capitalize">{contact.phone}</div>
              </div>
              <div className="flex-1">
                <div className="capitalize text-lg lg:text-xl font-bold text-xl text-white font-primary mb-4 rounded-2xl font-bold text-xl">
                  {hours.title}
                </div>
                <div className="flex flex-col lg:flex-row lg:gap-x-8">
                  {hours.program.map((item, index) => (
                    <div className="capitalize" key={index}>
                      <div className="capitalize">
                        <div className="font-bold text-yellow-200 text-2xl">{item.bussines}</div>
                        <div>{item.days}</div>
                        <div className="text-lg text-accent">{item.hours}</div>
                      </div>
                      <div className="capitalize">
                        <div className="font-bold text-yellow-200 text-2xl">{item.bussines2}</div>
                        <div>{item.days2}</div>
                        <div className="text-lg text-accent">{item.hours2}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex-1 lg:max-w-xs mb-12 text-center">
                <div className="capitalize text-lg lg:text-xl font-bold text-white text-2xl font-primary mb-4 rounded-full">
                  {social.title}
                </div>
                <Socials />
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </footer>
  );
};

export default Footer;
