import logo5taImg from "../assets/5ta2.png";
import logomirakiImg from "../assets/merakilogo.jpeg";
import logoMalditaDichaImg from "../assets/malditadicha.jpeg";
import logoLlegaderoImg from "../assets/llegadero.jpeg";
import Logo5taOff from "../assets/5ta.png";
import logoCelestialImg from "../assets/celestial.jpeg";
import logoParrrillaImg from "../assets/logoparrilla.jpeg";
import polosurtarject from "../assets/polosurlogo.png";

//IMAGES MERAKI

import hawallanapersonal from "../assets/meraki/Phawallnapersonal.jpeg";
import alascombo30 from "../assets/meraki/Combo30alitas.jpeg";
import alascombo12 from "../assets/meraki/Combo12alitas.jpeg";
import alascombo8 from "../assets/meraki/Combo8alitas.jpeg";
import alascombo6 from "../assets/meraki/Combo6alitas.jpeg";
import alascombo4 from "../assets/meraki/Combo4litas.jpeg";
import patatas1 from "../assets/meraki/PatatasMexico.jpeg";
import patatas2 from "../assets/meraki/patatasmont.png";
import patatas3 from "../assets/meraki/Patatasamericanas.jpeg";
import patatas4 from "../assets/meraki/PatatasRancheras.jpeg";
import patatas5 from "../assets/meraki/PatatasTradicionales.jpeg";
import pizza1 from "../assets/meraki/Pnutella.jpeg";
import pizza2 from "../assets/meraki/Pguayabayqueso.jpeg";
import pizza3 from "../assets/meraki/Pmerakifamialiar.jpeg";
import pizza4 from "../assets/meraki/Pmerakipersonal.jpeg";
import pizza5 from "../assets/meraki/Pvegetarianafamiliar.jpeg";
import pizza6 from "../assets/meraki/Pvegetarianapersonal.jpeg";
import pizza7 from "../assets/meraki/Pmargatitafamiliar.jpeg";
import pizza8 from "../assets/meraki/Pmargatirapersonal.jpeg";
import pizza9 from "../assets/meraki/Pbellaciaofamiala.jpeg";
import pizza10 from "../assets/meraki/Pbellaciaopersonal.jpeg";
import pizza11 from "../assets/meraki/Psupremafamiliar.jpeg";
import pizza12 from "../assets/meraki/Psupremapersonal.jpeg";
import pizza13 from "../assets/meraki/champifamiliar.png";
import pizza14 from "../assets/meraki/champifamiliar.png";
import pizza15 from "../assets/meraki/Pcarnesfamiliar.jpeg";
import pizza16 from "../assets/meraki/Pcarnespersonal.jpeg";
import pizza17 from "../assets/meraki/Phawallanafamiliar.jpeg";
import pizza18 from "../assets/meraki/Pjamosyquesofamiliar.jpeg";
import pizza19 from "../assets/meraki/Pjamonyquesopersonal.jpeg";
import lasaña1 from "../assets/meraki/vegetariana.png";
import lasaña2 from "../assets/meraki/mixta.png";
import lasaña3 from "../assets/meraki/Lasañapolloparmesano.jpeg";
import lasaña4 from "../assets/meraki/bbpork.jpeg";
import lasaña5 from "../assets/meraki/bbpork.jpeg";
import lasaña6 from "../assets/meraki/meetlovers.jpeg";
import lasaña7 from "../assets/meraki/Lasañapolloparmesano.jpeg";
import maicitos1 from "../assets/meraki/Maicitosmeraki.jpeg";
import maicitos2 from "../assets/meraki/Maicitosrancheros.jpeg";
import maicitos3 from "../assets/meraki/Maicitostradicionales.jpeg";
import platanos from "../assets/meraki/entradachipsplatano.jpeg";
import adicion1 from "../assets/meraki/SalchichaTradicional.jpeg";
import adicion2 from "../assets/meraki/SalchichaRanchera.jpeg";
import adicion3 from "../assets/meraki/HuevosCodornis.jpeg";
import adicion4 from "../assets/meraki/Chorizos4.jpeg";
import adicion5 from "../assets/meraki/tocineta.jpeg";
import adicion6 from "../assets/meraki/chicharron.jpeg";
import adicion7 from "../assets/meraki/Carnedesmechada.jpeg";
import adicion8 from "../assets/meraki/pollodesmecahdo.jpg";
import papas from "../assets/meraki/Papas.jpeg";

//IMAGES MALDTA DICHA

import burguer1 from "../assets/malditadicha/bomba.jpeg";
import burguer2 from "../assets/malditadicha/bomba2.jpeg";
import burguer3 from "../assets/malditadicha/maverik.jpeg";
import burguer4 from "../assets/malditadicha/blackhawk.jpeg";
import perro1 from "../assets/malditadicha/brigadier.jpeg";
import perro2 from "../assets/malditadicha/razo.jpeg";
import perro3 from "../assets/malditadicha/decombate.jpeg";
import perro4 from "../assets/malditadicha/dragoniante.jpeg";
import perro5 from "../assets/malditadicha/sargento.jpeg";
import perro6 from "../assets/malditadicha/teniente.jpeg";

//PARRILLA
import parrilla1 from "../assets/parrilla/bandejapaisa1.jpeg";
import parrilla2 from "../assets/parrilla/mondongo.jpeg";
import parrilla3 from "../assets/parrilla/tilapia.jpeg";
import parrilla4 from "../assets/parrilla/bandejapaisa.jpeg";
import parrilla5 from "../assets/parrilla/carneasada.jpeg";
import parrilla6 from "../assets/parrilla/puntaanca.jpeg";

//CELESTIA
import tortareve from "../assets/celestial/tortarevelved.jpg";
import tortachoco from "../assets/celestial/tortachocolate.jpg";
import tortazana from "../assets/celestial/tortazanahoria.jpg";
import postrelimon from "../assets/celestial/postrelimon.jpg";
import postrequeso from "../assets/celestial/postrequeso.jpg";
import aromatica from "../assets/celestial/aromaica.jpg";
import afogato from "../assets/celestial/afogato.jpg";
import gaseosa from "../assets/celestial/gaseosa400.jpg";
import hotbotella from "../assets/celestial/hitbotella.jpg";
import h2o from "../assets/celestial/h2o.jpeg";
import sodahatsubotella from "../assets/celestial/hatsu.jpg";
import sodalata from "../assets/celestial/hatsulata.jpg";
import teabotella from "../assets/celestial/hatsutea.jpg";
import teacaja from "../assets/celestial/hatsucajas.jpg";
import coronita from "../assets/celestial/coronita.jpg";
import stellar from "../assets/celestial/cervezastella.jpg";
import rose from "../assets/celestial/Rosada-3.jpg";
import gatorade from "../assets/celestial/gatorade.jpg";
import capuchino from "../assets/celestial/cappuccino.jpeg";
import cafeLeche from "../assets/celestial/cafe-con-leche.jpg";
import americano from "../assets/celestial/americano.jpeg";
import limonada from "../assets/celestial/limonada.jpeg";
import coldbrew from "../assets/celestial/coldbrew.jpeg";
import frio from "../assets/celestial/frio.jpeg";
import caliente from "../assets/celestial/caliente.jpeg";
import tinto from "../assets/celestial/tinto.jpeg";
import frappee from "../assets/celestial/frappepe.jpeg";
import latte from "../assets/celestial/latte.jpeg"
import frappe2 from "../assets/celestial/frappe2.jpeg";
import saborizada from "../assets/celestial/sodassaborizadas.jpeg";

//LLEGADERO
import licor1 from "../assets/llegadero/1800blanco.jpg";
import licor2 from "../assets/llegadero/TEQJIMADOR.jpg";
import licor3 from "../assets/llegadero/1800-ANEJO.png";
import licor4 from "../assets/llegadero/buca.jpg";
import licor5 from "../assets/llegadero/buca.jpg";
import licor6 from "../assets/llegadero/blacklabelbotella.jpg";
import licor7 from "../assets/llegadero/redlabel.jpg";
import licor8 from "../assets/llegadero/ronporfin.jpg";
import licor9 from "../assets/llegadero/medellinBOtella.jpg";
import licor10 from "../assets/llegadero/garrafalogo.png";
import licor11 from "../assets/llegadero/RON-VIEJO-DE-CALDAS-8-ANOS.png";
import licor12 from "../assets/llegadero/gordon.jpg";
import licor13 from "../assets/llegadero/tanqueray.jpg";
import licor14 from "../assets/llegadero/roncaldas.jpg";
import licor15 from "../assets/llegadero/aguila1.jpg";
import licor16 from "../assets/llegadero/aguilalight.jpg";
import licor17 from "../assets/llegadero/clubcolombia.jpg";
import licor18 from "../assets/llegadero/cerveza-poker.jpg";
import licor19 from "../assets/llegadero/pilsen.jpg";
import licor20 from "../assets/llegadero/corona.png";
import licor101 from "../assets/llegadero/garrafa-verde.png";
import licor102 from "../assets/llegadero/roja.jpg";


//POLO SUR
import polo1 from "../assets/polosur/banana155.jpg";
import polo2 from "../assets/polosur/browniwhelado12.jpg";
import polo3 from "../assets/polosur/copaqueso13.jpg";
import polo4 from "../assets/polosur/frutosamarillos13.jpg";
import polo5 from "../assets/polosur/malteada10.jpg";
import polo6 from "../assets/polosur/malteadaespecial15.jpg";
import polo7 from "../assets/polosur/micheladagranizada8.jpg";
import polo8 from "../assets/polosur/oreo13.jpg";
import polo9 from "../assets/polosur/salpiconespecial15.jpg";
import polo10 from "../assets/polosur/salpiconespecial15.jpg";

//ABOUT
import aboutImgFull from "../assets/originbob.png";
import computerImg from "../assets/pn.png";
import image5ta from "../assets/foodorfull.png";
//FA FA ICONS
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

export const data = {
  meraki: [
    {
      id: 1,
      name: "Pizza Hawallana Personal",
      category: "meraki",
      image: hawallanapersonal,
      price: "22.000",
    },
    {
      id: 2,
      name: "Combo 30 alitas",
      category: "meraki",
      image: alascombo30,
      price: "63.000",
    },
    {
      id: 3,
      name: "Combo 12 alitas",
      category: "meraki",
      image: alascombo12,
      price: "28.000",
    },
    {
      id: 4,
      name: "Combo 8 alitas",
      category: "meraki",
      image: alascombo8,
      price: "19.000",
    },
    {
      id: 5,
      name: "Combo 6 alitas",
      category: "meraki",
      image: alascombo6,
      price: "17.000",
    },
    {
      id: 6,
      name: "Combo 4 alitas",
      category: "meraki",
      image: alascombo4,
      price: "15.000",
    },
    {
      id: 7,
      name: "Patatas Mexicanas",
      category: "meraki",
      image: patatas1,
      price: "18.000",
    },
    {
      id: 8,
      name: "Patatas Montañeras",
      category: "meraki",
      image: patatas2,
      price: "19.000",
    },
    {
      id: 9,
      name: "Patatas Americanas",
      category: "meraki",
      image: patatas3,
      price: "18.000",
    },
    {
      id: 10,
      name: "Patatas Rancheras",
      category: "meraki",
      image: patatas4,
      price: "18.000",
    },
    {
      id: 11,
      name: "Patatas Tradicionales",
      category: "meraki",
      image: patatas5,
      price: "15.000",
    },
    {
      id: 12,
      name: "Pizza Nutella Tradicional",
      category: "meraki",
      image: pizza1,
      price: "17.000",
    },
    {
      id: 13,
      name: "Pizza Guayaba y Queso Personal",
      category: "meraki",
      image: pizza2,
      price: "17.000",
    },
    {
      id: 14,
      name: "Pizza Meraki Familiar",
      category: "meraki",
      image: pizza3,
      price: "45.000",
    },
    {
      id: 15,
      name: "Pizza Meraki Personal",
      category: "meraki",
      image: pizza4,
      price: "24.000",
    },
    {
      id: 16,
      name: "Pizza Vegetariana Familiar",
      category: "meraki",
      image: pizza5,
      price: "33.000",
    },
    {
      id: 17,
      name: "Pizza Vegetariana Personal",
      category: "meraki",
      image: pizza6,
      price: "18.000",
    },
    {
      id: 18,
      name: "Pizza Margarita Familiar",
      category: "meraki",
      image: pizza7,
      price: "30.000",
    },
    {
      id: 19,
      name: "Pizza Margarita Personal",
      category: "meraki",
      image: pizza8,
      price: "15.000",
    },
    {
      id: 20,
      name: "Pizza Bella Ciao Familiar",
      category: "meraki",
      image: pizza9,
      price: "45.000",
    },
    {
      id: 21,
      name: "Pizza Bella Ciao Personal",
      category: "meraki",
      image: pizza10,
      price: "24.000",
    },
    {
      id: 22,
      name: "Pizza Suprema Familiar",
      category: "meraki",
      image: pizza11,
      price: "49.000",
    },
    {
      id: 23,
      name: "Pizza Suprema Personal",
      category: "meraki",
      image: pizza12,
      price: "27.000",
    },
    {
      id: 24,
      name: "Pizza Pollo Champiñonesa Familiar",
      category: "meraki",
      image: pizza13,
      price: "45.000",
    },
    {
      id: 25,
      name: "Pizza Pollo Champiñonesa Personal",
      category: "meraki",
      image: pizza14,
      price: "24.000",
    },
    {
      id: 26,
      name: "Pizza Carnes Familiar",
      category: "meraki",
      image: pizza15,
      price: "45.000",
    },
    {
      id: 27,
      name: "Pizza Carnes Personal",
      category: "meraki",
      image: pizza16,
      price: "24.000",
    },
    {
      id: 28,
      name: "Pizza Hawallana Familiar",
      category: "meraki",
      image: pizza17,
      price: "40.000",
    },
    {
      id: 29,
      name: "Pizza Jamon y Queso Familiar",
      category: "meraki",
      image: pizza18,
      price: "38.000",
    },
    {
      id: 30,
      name: "Pizza Jamon y Queso Personal",
      category: "meraki",
      image: pizza19,
      price: "20.000",
    },
    {
      id: 31,
      name: "Lasaña Vegetariana",
      category: "meraki",
      image: lasaña1,
      price: "24.000",
    },
    {
      id: 32,
      name: "Lasaña Mixta",
      category: "meraki",
      image: lasaña2,
      price: "26.000",
    },
    {
      id: 33,
      name: "Lasaña Pollo Parmesano",
      category: "meraki",
      image: lasaña3,
      price: "24.000",
    },
    {
      id: 34,
      name: "Lasaña Boloñesa",
      category: "meraki",
      image: lasaña4,
      price: "24.000",
    },
    {
      id: 307,
      name: "Lasaña Mix Meet Lovers",
      category: "meraki",
      image: lasaña6,
      price: "24.000",
    },
    {
      id: 308,
      name: "Lasaña BBQ PORK",
      category: "meraki",
      image: lasaña5,
      price: "24.000",
    },
    {
      id: 309,
      name: "Lasaña pollo champiñones",
      category: "meraki",
      image: lasaña7,
      price: "24.000",
    },
    {
      id: 35,
      name: "Maicitos Meraki",
      category: "meraki",
      image: maicitos1,
      price: "23.000",
    },
    {
      id: 36,
      name: "Maicitos Rancheros",
      category: "meraki",
      image: maicitos2,
      price: "21.000",
    },
    {
      id: 37,
      name: "Maicitos Tradicionales",
      category: "meraki",
      image: maicitos3,
      price: "19.000",
    },
    {
      id: 38,
      name: "Entradas Chips Platanos",
      category: "meraki",
      image: platanos,
      price: "11.000",
    },
    {
      id: 300,
      name: "Adicion Salchicha Tradicional",
      category: "meraki",
      image: adicion1,
      price: "4.000",
    },
    {
      id: 301,
      name: "Adicion Salchicha Ranchera",
      category: "meraki",
      image: adicion2,
      price: "4.500",
    },
    {
      id: 302,
      name: "Adicion Huevos De Codorniz",
      category: "meraki",
      image: adicion3,
      price: "4.000",
    },
    {
      id: 303,
      name: "Adicion Chorizos",
      category: "meraki",
      image: adicion4,
      price: "4.500",
    },
    {
      id: 304,
      name: "Adicion Tocineta",
      category: "meraki",
      image: adicion5,
      price: "4.500",
    },
    {
      id: 305,
      name: "Adicion Chicharron",
      category: "meraki",
      image: adicion6,
      price: "5.500",
    },
    {
      id: 306,
      name: "Adicion Carne Desmechada",
      category: "meraki",
      image: adicion7,
      price: "5.500",
    },
    {
      id: 306,
      name: "Adicion Pollo Desmechado",
      category: "meraki",
      image: adicion8,
      price: "5.500",
    },
    {
      id: 307,
      name: "Solo Papas",
      category: "meraki",
      image: papas,
      price: "11.000",
    },
  ],
  malditaDicha: [
    {
      id: 39,
      name: "Hamburguesa Tanqueta",
      category: "malditaDicha",
      image: burguer1,
      price: "25.000",
    },
    {
      id: 40,
      name: "Hamburguesa Willys",
      category: "malditaDicha",
      image: burguer2,
      price: "17.000",
    },
    {
      id: 41,
      name: "Hamburguesa Maverick",
      category: "malditaDicha",
      image: burguer3,
      price: "20.000",
    },
    {
      id: 42,
      name: "Hamburguesa Black Hawk",
      category: "malditaDicha",
      image: burguer4,
      price: "17.000",
    },
    {
      id: 43,
      name: "Perro Brigadier General",
      category: "malditaDicha",
      image: perro1,
      price: "15.000",
    },
    {
      id: 44,
      name: "Perrito Razo",
      category: "malditaDicha",
      image: perro2,
      price: "9.000",
    },
    {
      id: 45,
      name: "Perrito Dragoniante",
      category: "malditaDicha",
      image: perro3,
      price: "6.000",
    },
    {
      id: 46,
      name: "Perro Sargento",
      category: "malditaDicha",
      image: perro4,
      price: "12.000",
    },
    {
      id: 47,
      name: "Perro Teniente Corbeta",
      category: "malditaDicha",
      image: perro5,
      price: "12.000",
    },
    {
      id: 48,
      name: "Perrito de Combate",
      category: "malditaDicha",
      image: perro6,
      price: "6.000",
    },
  ],
  parrilla: [
    {
      id: 401,
      name: "Bandeja Paisa",
      category: "parrilla",
      image: parrilla1,
    },
    {
      id: 402,
      name: "Mondongo",
      category: "parrilla",
      image: parrilla2,
    },
    {
      id: 403,
      name: "Tilapia",
      category: "parrilla",
      image: parrilla3,
    },
    {
      id: 404,
      name: "Bandeja",
      category: "parrilla",
      image: parrilla4,
    },
    {
      id: 405,
      name: "Carne Asada Con Papitas",
      category: "parrilla",
      image: parrilla5,
    },
    {
      id: 406,
      name: "Punta De Anca",
      category: "parrilla",
      image: parrilla6,
    },
  ],
  llegadero: [
    {
      id: 100,
      name: "1800 Tequila Blanco",
      category: "llegadero",
      image: licor1,
      price: "",
    },
    {
      id: 101,
      name: "Jimador Tequila",
      category: "llegadero",
      image: licor2,
      price: "",
    },
    {
      id: 102,
      name: "1800 Añejo",
      category: "llegadero",
      image: licor3,
      price: "",
    },
    {
      id: 103,
      name: "Buchanas Botella 12 Años",
      category: "llegadero",
      image: licor4,
      price: "",
    },
    {
      id: 105,
      name: "Buchanas Litro 12 Años",
      category: "llegadero",
      image: licor5,
      price: "",
    },
    {
      id: 106,
      name: "Black Label Botella Whisky",
      category: "llegadero",
      image: licor6,
      price: "",
    },
    {
      id: 107,
      name: "Red Label",
      category: "llegadero",
      image: licor7,
      price: "",
    },
    {
      id: 108,
      name: "Ron 5 Años Extra Añejo",
      category: "llegadero",
      image: licor8,
      price: "",
    },
    {
      id: 109,
      name: "Ron Medellin 3 Años Botella",
      category: "llegadero",
      image: licor9,
      price: "",
    },
    {
      id: 110,
      name: "Garrafa Aguardiente (Azul)",
      category: "llegadero",
      image: licor10,
      price: "",
    },
    {
      id: 110,
      name: "Garrafa Aguardiente (Verde)",
      category: "llegadero",
      image: licor101,
      price: "",
    },
    {
      id: 110,
      name: "Garrafa Aguardiente (Roja)",
      category: "llegadero",
      image: licor102,
      price: "",
    },
    {
      id: 112,
      name: "Ron Caldas Carta De Oro",
      category: "llegadero",
      image: licor11,
      price: "",
    },
    {
      id: 113,
      name: "Gordon's Pink",
      category: "llegadero",
      image: licor12,
      price: "",
    },
    {
      id: 114,
      name: "Tanqueray Botella",
      category: "llegadero",
      image: licor13,
      price: "",
    },
    {
      id: 115,
      name: "Ron Caldas Media",
      category: "llegadero",
      image: licor14,
      price: "",
    },
    {
      id: 116,
      name: "Cerveza Aguila",
      category: "llegadero",
      image: licor15,
      price: "4.000",
    },
    {
      id: 117,
      name: "Cerveza Aguila Light",
      category: "llegadero",
      image: licor16,
      price: "4.000",
    },
    {
      id: 118,
      name: "Cerveza Club Colombia",
      category: "llegadero",
      image: licor17,
      price: "6.000",
    },
    {
      id: 119,
      name: "Cerveza Poker",
      category: "llegadero",
      image: licor18,
      price: "4.000",
    },
    {
      id: 120,
      name: "Cervea Pilsen",
      category: "llegadero",
      image: licor19,
      price: "4.000",
    },
    {
      id: 121,
      name: "Cerveza Coronita",
      category: "llegadero",
      image: licor20,
      price: "5.000",
    },
    {
      id: 122,
      name: "Cerveza Corona",
      category: "llegadero",
      image: licor20,
      price: "6.000",
    },
  ],
  celestial: [
    {
      id: 69,
      name: "Moccachino Caliente",
      category: "celestial",
      image: caliente,
      price: "7.000",
    },
    {
      id: 68,
      name: "ColdBrew",
      category: "celestial",
      image: coldbrew,
      price: "8.000",
    },
    {
      id: 67,
      name: "Moccachino Frio",
      category: "celestial",
      image: frio,
      price: "7.000",
    },
    {
      id: 66,
      name: "Limonada De Café",
      category: "celestial",
      image: limonada,
      price: "8.000",
    },
    {
      id: 62,
      name: "Frappé Sin Chantilly",
      category: "celestial",
      image: frappee,
      price: "8.000",
    },
    {
      id: 62,
      name: "Frappé Con Chantilly",
      category: "celestial",
      image: frappe2,
      price: "10.000",
    },
    {
      id: 65,
      name: "Latte",
      category: "celestial",
      image: latte,
      price: "6.500",
    },
    {
      id: 88,
      name: "Capuchino",
      category: "celestial",
      image: capuchino,
      price: "5.000",
    },
    {
      id: 89,
      name: "Cafe en Leche",
      category: "celestial",
      image: cafeLeche,
      price: "3.000",
    },
    {
      id: 90,
      name: "Americano",
      category: "celestial",
      image: americano,
      price: "2.000",
    },
    {
      id: 64,
      name: "Tinto",
      category: "celestial",
      image: tinto,
      price: "2.000",
    },
    {
      id: 70,
      name: "Torta Revelved",
      category: "celestial",
      image: tortareve,
      price: "8.000",
    },
    {
      id: 71,
      name: "Torta Chocolate",
      category: "celestial",
      image: tortachoco,
      price: "8.000",
    },
    {
      id: 72,
      name: "Torta Zanahoria",
      category: "celestial",
      image: tortazana,
      price: "7.000",
    },
    {
      id: 73,
      name: "Postre Limon",
      category: "celestial",
      image: postrelimon,
      price: "7.500",
    },
    {
      id: 74,
      name: "Postre Queso",
      category: "celestial",
      image: postrequeso,
      price: "7.500",
    },
    {
      id: 75,
      name: "Aromatica",
      category: "celestial",
      image: aromatica,
      price: "2.000",
    },
    {
      id: 76,
      name: "Afogato",
      category: "celestial",
      image: afogato,
      price: "9.000",
    },
    {
      id: 77,
      name: "Gaseosa 400ml",
      category: "celestial",
      image: gaseosa,
      price: "4.000",
    },
    {
      id: 78,
      name: "Hit Botella",
      category: "celestial",
      image: hotbotella,
      price: "3.500",
    },
    {
      id: 91,
      name: "Agua Saborizada",
      category: "celestial",
      image: saborizada,
      price: "3.000",
    },
    {
      id: 79,
      name: "H2O Botella",
      category: "celestial",
      image: h2o,
      price: "3.500",
    },
    {
      id: 80,
      name: "Soda Hatsu Botella",
      category: "celestial",
      image: sodahatsubotella,
      price: "4.500",
    },
    {
      id: 81,
      name: "Soda Hatsu Lata",
      category: "celestial",
      image: sodalata,
      price: "3.000",
    },
    {
      id: 82,
      name: "Tea Hatsu Botella",
      category: "celestial",
      image: teabotella,
      price: "6.000",
    },
    {
      id: 83,
      name: "Tea Hatsu Caja",
      category: "celestial",
      image: teacaja,
      price: "3.500",
    },
    {
      id: 84,
      name: "Coronita",
      category: "celestial",
      image: coronita,
      price: "4.500",
    },
    {
      id: 85,
      name: "Cerveza Stellar",
      category: "celestial",
      image: stellar,
      price: "8.000",
    },
    {
      id: 86,
      name: "Cerveza 3 Cordilleras Rose",
      category: "celestial",
      image: rose,
      price: "6.000",
    },
    {
      id: 87,
      name: "Gatorade",
      category: "celestial",
      image: gatorade,
      price: "4.500",
    },
  ],
  polosur: [
    {
      id: 1000,
      name: "Banana Split",
      category: "polosur",
      image: polo1,
      price: "15.500",
    },
    {
      id: 1001,
      name: "Brownie con helado",
      category: "polosur",
      image: polo2,
      price: "12.000",
    },
    {
      id: 1002,
      name: "Copa Queso",
      category: "polosur",
      image: polo3,
      price: "13.000",
    },
    {
      id: 1003,
      name: "Copa Frutas Amarillos",
      category: "polosur",
      image: polo4,
      price: "13.000",
    },
    {
      id: 1004,
      name: "Malteada",
      category: "polosur",
      image: polo5,
      price: "10.000",
    },
    {
      id: 1005,
      name: "Malteada Especial",
      category: "polosur",
      image: polo6,
      price: "15.000",
    },
    {
      id: 1006,
      name: "Micheladas Granizadas",
      category: "polosur",
      image: polo7,
      price: "8.000",
    },
    {
      id: 1007,
      name: "Copa Oreo",
      category: "polosur",
      image: polo8,
      price: "13.000",
    },
    {
      id: 1008,
      name: "Salpicon Especial",
      category: "polosur",
      image: polo9,
      price: "15.000",
    },
    {
      id: 1009,
      name: "Salpicon Sencillo",
      category: "polosur",
      image: polo10,
      price: "12.000",
    },
  ]
};

export const categories = [
  {
    id: 1,
    name: "Fast Food",
    image:
      "https://duyt4h9nfnj50.cloudfront.net/new_search_home_eats_icon/FastFood_BrowseHome@3x.png",
  },
  {
    id: 2,
    name: "Pizza",
    image:
      "https://duyt4h9nfnj50.cloudfront.net/new_search_home_eats_icon/Pizza_BrowseHome@3x.png",
  },
  {
    id: 3,
    name: "Wings",
    image:
      "https://duyt4h9nfnj50.cloudfront.net/new_search_home_eats_icon/Wings_BrowseHome@3x.png",
  },
  {
    id: 4,
    name: "Co-working",
    image: computerImg,
  },
];

export const LogoOfficial = {
  logo5ta: Logo5taOff,
};

export const headerData = {
  image: logo5taImg,
};

export const dataBussins = {
  image: logomirakiImg,
  image2: logoMalditaDichaImg,
  image3: logoLlegaderoImg,
  image4: logoCelestialImg,
  image5: logoParrrillaImg,
  image6: polosurtarject
};

export const aboutData5ta = {
  pretitle: "LA QUINTA",
  title: "Food & Mall",
  subtitle:
    "Nuestros espacios son ideales para disfrutar comida deliciosa, crear lazos, contar historias, conocer amigos y celebrar la vida!! 🍕🍟🍗🍝🍻🍾🍹",
  image: aboutImgFull,
};

export const imagen5taOrigin = {
  image2: image5ta,
};

export const newsletterData = {
  title: "Muchas Gracias Por Visitar Nuestra Página",
  subtitle:
    "Dejanos una reseña de cual fue tu experiencia con nuestro servicio",
  placeholder: "escribe tu mensaje",
  btnText: "enviar",
};

export const footerData = {
  contact: {
    title: "contacto",
    address: "Parque principal del Valparaiso-Antioquia",
    phone: "(+57) 324 306 97 65",
  },
  hours: {
    title: "Horario de trabajo",
    program: [
      {
        bussines: "Meraki - Maldita Dicha",
        days: "lunes - viernes",
        hours: "04:00 PM - 10:00 PM",
      },
      {
        days: "sabado - domingo",
        hours: "01:00 PM - 10:00 PM",
      },
      {
        bussines2: "Celestial",
        days2: "Todos los dias",
        hours2: "08:00 AM - 10:00 PM",
      },
    ],
  },
  social: {
    title: "red social",
    icons: [
      {
        href: "https://www.youtube.com/watch?v=RqtkFXzV5T8",
        icon: <FaYoutube />,
      },
      {
        href: "https://www.facebook.com/profile.php?id=61558588149360",
        icon: <FaFacebook />,
      },
      {
        href: "https://www.instagram.com/la_quinta.plazoleta/",
        icon: <FaInstagram />,
      },
    ],
  },
};

export const socialData = [
  { href: "https://www.youtube.com/watch?v=RqtkFXzV5T8", icon: <FaYoutube /> },
  {
    href: "https://www.facebook.com/profile.php?id=61558588149360",
    icon: <FaFacebook />, 
  },
  { href: "https://www.instagram.com/la_quinta.plazoleta/", icon: <FaInstagram /> },
];
