import React, { useState } from "react";
import { newsletterData } from "../data/data";
import { FaWhatsapp } from "react-icons/fa";

const Newsletter = () => {
  const { title, subtitle, placeholder, btnText } = newsletterData;
  const [message, setMessage] = useState("");

  const handleWhatsAppClick = () => {
    const initialText = "📳Hola LA QUINTA food & mall plazoleta, esta reseña es enviada desde la página web:\n\n 📫";
    const fullMessage = `${initialText}${message}`;
    const encodedMessage = encodeURIComponent(fullMessage);
    const whatsappLink = `https://wa.me/573243069765?text=${encodedMessage}`;
    window.open(whatsappLink, "_blank");
    setMessage("");
  };

  return (
    <div className="bg-none lg:bg-newsletter lg:bg-cover lg:h-[220px] lg:bg-center lg:px-24 xl:bg-auto">
      <div className="flex flex-col lg:flex-row justify-between items-center h-full">
        <div className="text-center lg:text-left mb-4">
          <h3 className="text-[26px] font-primary italic text-white capitalize mb-2 font-bold text-4xl">
            {title}
          </h3>
          <p className="text-white font-bold ">{subtitle}</p>
        </div>
        <form className="flex flex-col lg:flex-row lg:gap-x-[10px] gap-y-4 lg:items-center">
          <textarea
            className="rounded-xl m-4 border-blue-500 bg-gray-800 text-white placeholder:font-light placeholder:text-center placeholder:tet-white/20 text-white focus:ring-1 focus:ring-accent border border-white/20 transition-all"
            type="text"
            placeholder={placeholder}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button
            className="btn capitalize w-full  w-25 h-10 lg:max-w-[204px] text-green-500 bg-gray-800 border-green-500"
            onClick={handleWhatsAppClick}
          >
            <FaWhatsapp className="inline-block mr-2 text-green-500" />
            {btnText}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Newsletter;
