import React from "react";
import { dataBussins } from "../data/data";

const HeadlineCards = () => {
  const { image, image2, image3, image4, image5, image6 } = dataBussins;

  return (
    <div className="max-w-[1640px] mx-auto p-4 py-12 grid md:grid-cols-3 gap-6">
      {/* Card 1 */}
      <div className="rounded-xl relative">
        {/* Overlay */}
        <div className="absolute w-full h-full bg-black/20 rounded-xl text-white">
          <p className="font-bold text-2xl px-2 pt-4">Meraki Valparaiso</p>
          <p className="px-2">Patatas and Pizza</p>
          <p
            className="border-white rounded-xl text-3xl text-black mx-2 absolute bottom-4" >
              🍕 🍟
          </p>
        </div>
        <img
          className="max-h-[160px] md:max-h-[200px] w-full object-contain rounded-xl"
          src={image}
          alt="Meraki Valparaiso"
        />
      </div>
      {/* Card 2 */}
      <div className="rounded-xl relative">
        {/* Overlay */}
        <div className="absolute w-full h-full bg-black/20 rounded-xl text-white">
          <p className="font-bold text-2xl px-2 pt-4">Maldita Dicha</p>
          <p className="px-2">Delicius</p>
          <p
            className="border-white rounded-xl text-3xl text-black mx-2 absolute bottom-4"           >
              🍔 🌭
          </p>
        </div>
        <img
          className="max-h-[160px] md:max-h-[200px] w-full object-contain rounded-xl"
          src={image2}
          alt="Maldita Dicha"
        />
      </div>
      {/* Card 3 */}
      <div className="rounded-xl relative">
        {/* Overlay */}
        <div className="absolute w-full h-full bg-black/20 rounded-xl text-white">
          <p className="font-bold text-2xl px-2 pt-4">Celestial</p>
          <p className="px-2">Cafes Deliciosos</p>
          <p
            className="border-white rounded-xl text-3xl text-black mx-2 absolute bottom-4"          >
            
            🍰 ☕
          </p>
        </div>
        <img
          className="max-h-[160px] md:max-h-[200px] w-full object-contain rounded-xl"
          src={image4}
          alt="Celestial"
        />
      </div>
      {/* Card 4 */}
      <div className="rounded-xl relative">
        {/* Overlay */}
        <div className="absolute w-full h-full bg-black/20 rounded-xl text-white">
          <p className="font-bold text-2xl px-2 pt-4">Parrilla</p>
          <p className="px-2">Tasty Treats</p>
          <p
            className="border-white rounded-xl text-3xl text-black mx-2 absolute bottom-4"
          >
            🥩 🍛
          </p>
        </div>
        <img
          className="max-h-[160px] md:max-h-[200px] w-full object-cover rounded-xl"
          src={image5}
          alt="Parrilla"
        />
      </div>
      {/* Card 5 */}
      <div className="rounded-xl relative">
        {/* Overlay */}
        <div className="absolute w-full h-full bg-black/20 rounded-xl text-white">
          <p className="font-bold text-2xl px-2 pt-4">Llegadero</p>
          <p className="px-2">Bebidas y Más</p>
          <p
            className="border-white rounded-xl text-3xl text-black mx-2 absolute bottom-4"
          >
            🍻 🍾
          </p>
        </div>
        <img
          className="max-h-[160px] md:max-h-[200px] w-full object-contain rounded-xl"
          src={image3}
          alt="Llegadero"
        />
      </div>
        {/* Card 6 */}
        <div className="rounded-xl relative">
        {/* Overlay */}
        <div className="absolute w-full h-full bg-black/20 rounded-xl text-white">
          <p className="font-bold text-2xl px-2 pt-4">Polo Sur</p>
          <p className="px-2">Helados y Más</p>
          <p
            className="border-white rounded-xl text-3xl text-black mx-2 absolute bottom-4"
          >
            🍨 🍧
          </p>
        </div>
        <img
          className="max-h-[160px] md:max-h-[200px] w-full object-cover rounded-xl"
          src={image6}
          alt="Polo Sur"
        />
      </div>
    </div>
  );
};

export default HeadlineCards;
