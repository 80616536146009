import React from 'react'
import { headerData } from '../data/data';

const Hero = () => {

  const { image } = headerData;
  return ( 
    <div className='max-w-[1640px] mx-auto p-4'>
        <div className='max-h-[500px] relative'>
            {/* Overlay */}
            <div className='absolute  w-full h-full text-gray-200 max-h-[500px] bg-black/20 flex flex-col justify-center'>
                <h1 className='px-2  text-3xl sm:text-5xl md:text-6xl lg:text-7xl font-bold'>LA <span className='text-orange-500'>QUINTA</span></h1>
                <h1 className='px-3 text-3xl sm:text-5xl md:text-6xl lg:text-7xl font-bold'>Food <span className='text-orange-500'>& </span>Mall</h1>
                <h1 className='px-4 text-3xl sm:text-5xl md:text-6xl lg:text-6xl font-bold'><span className='text-orange-500'>PLAZOLETA</span></h1>
            </div>
            <img className='w-full max-h-[500px] object-cover' src={image} alt="/" />
        </div>
    </div>
  )
}

export default Hero