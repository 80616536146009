import React from "react";
import { aboutData5ta, imagen5taOrigin } from "../data/data";
import { motion } from "framer-motion";
import { fadeIn, plateVariants, staggerContainer } from "../variants";

const About = () => {
  const {subtitle, image } = aboutData5ta;
  const {image2} = imagen5taOrigin;

  return (
    <section className="min-h[620px] text-white font-bold" id="about">
      <div className="container mx-auto min-h-[620px]">
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView={'show'}
          viewport={{ once: false, amount: 0.6 }}
          className="min-h-[620px] flex flex-col lg:flex-row items-center"
        >
          <motion.div
            variants={fadeIn('right', 'tween', 0.2, 1.8)}
            className="flex-1 text-center lg:text-left"
          >
            <img src={image2} alt="" style={{ width: "100%", height: "auto" }}/>
            <p className="mb-8 max-w-[560px] text-2xl m-3">{subtitle}</p>
          </motion.div>
          <motion.div
            variants={plateVariants}
            className="flex-1"
          >
            <img src={image} alt="" style={{ width: "100%", height: "auto" }} />
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default About;
