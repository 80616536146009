import React from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import HeadlineCards from "./components/HeadlineCards";
import Food from "./components/Food";
import Category from "./components/Category";
import About from "./components/Abuot";
import Footer from "./components/Footer";
import VideoSection from "./components/VideoSection";

function App() {
  return (
    <div className="bg-site bg-attachment bg-center bg-auto overflow-hidden">
      <Navbar />
      <Hero />
      <HeadlineCards />
      <About />
      <Food />
      <VideoSection />
      <Category />
      <Footer />
      
    </div>
  );
}

export default App;
