import React, { useState, useEffect } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { BsFillCartFill } from "react-icons/bs";
import { GiChefToque } from "react-icons/gi";
import { FaUserFriends, FaPencilAlt } from "react-icons/fa";
import { FaHamburger } from "react-icons/fa";
import { LogoOfficial } from "../data/data";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [nav, setNav] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
        setIsAtTop(false);
      } else {
        setScrolled(false);
        setIsAtTop(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setNav(!nav);
  };

  const closeMenu = () => {
    setNav(false);
  };

  const handleNavLinkClick = (event) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute("href").substring(1);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
      closeMenu();
    }
  };

  const navbarClasses = `max-w-[1640px] mx-auto flex justify-between items-center p-4 transition-all ${
    scrolled ? "bg-black/50 py-0 lg:hidden" : ""
  } ${isAtTop ? "" : "fixed top-0"}`;

  return (
    <div className={navbarClasses} style={{ width: "100%", zIndex: 1000 }} id="navbar">
      <div className="flex items-center">
        <div onClick={toggleMenu} className="cursor-pointer">
          {nav ? (
            <AiOutlineClose size={30} color="white" />
          ) : (
            <AiOutlineMenu size={30} color="white" />
          )}
        </div>
        <h1 className="text-2xl sm:text-3xl lg:text-4xl px-2 text-white">
          LA<span className="font-bold text-orange-500">QUINTA</span>
        </h1>
        <div className="hidden lg:flex items-center bg-gray-200 rounded-full p-1 text-[14px]">
          <p className="bg-black text-white rounded-full p-2">Delivery</p>
          <p className="p-2">Pickup</p>
        </div>
      </div>

      <div className="flex items-center px-2 w-[200px] sm:w-[400px] lg:w-[500px]">
        <div className="ml-auto">
          <img src={LogoOfficial.logo5ta} alt="Logo" className="w-100 h-100" />
        </div>
      </div>

      <button className="bg-black text-white hidden md:flex items-center py-2 rounded-full">
        <BsFillCartFill size={20} className="mr-2" /> Cart
      </button>

      {nav && (
        <div
          onClick={toggleMenu}
          className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0"
        ></div>
      )}

      <div
        className={
          nav
            ? "fixed top-0 left-0 w-[300px] h-screen bg-card bg-cover text-white z-10 duration-300"
            : "fixed top-0 left-[-100%] w-[300px] h-screen bg-white z-10 duration-300"
        }
      >
        <AiOutlineClose
          onClick={toggleMenu}
          size={30}
          className="absolute right-4 top-4 cursor-pointer"
        />
        <h2 className="text-4xl p-4">
          LA <span className="font-bold text-black">QUINTA</span>
        </h2>
        <nav>
          <ul className="flex flex-col p-6 text-white font-bold ">
            <li className="text-2xl py-4 flex">
              <a href="#about" onClick={handleNavLinkClick}><GiChefToque size={25} className="mr-4 text-red-500" /> Nosotros</a>
            </li>
            <li className="text-2xl py-4 flex">
              <a href="#meraki" onClick={handleNavLinkClick}><FaHamburger size={25} className="mr-4 text-red-500" /> Comidas</a>
            </li>
            <li className="text-2xl py-4 flex">
              <a href="#contact" onClick={handleNavLinkClick}><FaPencilAlt  size={25} className="mr-4 text-red-500" /> Reseña</a>
            </li>
            <li className="text-2xl py-4 flex">
              <a href="#contact" onClick={handleNavLinkClick}><FaUserFriends size={25} className="mr-4 text-red-500" /> Contactos</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
