import React, { useRef, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import videoFile from '../assets/video/video.mp4';

const VideoSection = () => {
  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      if (inView) {
        videoRef.current.play().catch(error => {
          console.log('Error attempting to play', error);
        });
      } else {
        videoRef.current.pause();
      }
    }
  }, [inView]);

  console.log('inView:', inView);

  return (
    <div className="w-full min-h-screen bg-parkk bg-cover flex flex-col items-center justify-center p-4">
      <div className="w-full text-center p-4">
        <h1 className="font-bold text-white text-6xl sm:text-8xl">
          VILLA CAMILA
        </h1>
        <h2 className="font-bold text-white text-2xl sm:text-4xl mt-4">
          UNA ENCANTADORA FINCA PARA CELEBRAR CON TUS AMIGOS ESAS FECHAS ESPECIALES
        </h2>
      </div>
      <div
        ref={ref}
        className="w-full max-w-4xl border-4 border-yellow-500 rounded-lg overflow-hidden mt-8 mb-16"
      >
        {inView ? (
          <video
            ref={videoRef}
            src={videoFile}
            controls
            className="w-full h-auto"
            onPause={() => videoRef.current.pause()}
          />
        ) : (
          <div className="w-full h-auto bg-blue-500 p-4">
            <p className="text-white text-center">
              Scroll down to view the video
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoSection;
